.bordered-row td,
.bordered-row:last-child td {
    border: 1px solid #cfcecf;
    border-right: 0;
}
.bordered-row td:first-child {
    border: 0;
}
.bordered-row td:last-child {
    border: 0;
    border-left: 1px solid #cfcecf;
}
.ant-table-summary tr {
    background: #f1e7e7;
}
.ant-table-summary td.ant-table-cell {
    vertical-align: top;
}
.ant-table-summary .ant-table-cell .ant-btn-link {
    padding: 0;
}
