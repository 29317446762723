.status-disabled {
    color: gray;
}

.status-scheduled {
    color: darkgreen;
}

.status-enqueued {
    color: darkblue;
}

.status-executing {
    color: darkblue;
}

.ant-table-tbody > tr.status-completed td,
.ant-table-tbody > tr.status-completed:hover,
.ant-table-tbody > tr.status-completed td.ant-table-cell-row-hover {
    color: black;
    background-color: #DFF3D5;
}

.status-error {
    color: darkred;
}

.select-text-for-wrap {
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.dropdown-scroll {
    height: 450px;
    overflow-y: scroll;
    border-radius: 10px;
}
