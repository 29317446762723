.tracker {
    padding: 10px;
    border-bottom: 1px solid #EEE;
    position: relative;
    width: 300px;
}

.tracker:hover {
    background-color: #f3f3f3;
}

.tracker .label {
    display: inline-block;
}

.tracker .block-control {
    display: inline-block;
    position: absolute;
    right: 40px;
}

.tracker .state {
    display: inline-block;
    position: absolute;
    right: 10px;
    border: 1px solid #FFF;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.state-active {
    background-color: #6ACF00;
}

.state-offline {
    background-color: #FE6801;
}

.state-signal_lost {
    background-color: #FE6801;
}

.state-idle {
    background-color: #0495FA;
}

.state-just_registered {
    background-color: #0495FA;
}

.history-event {
    padding: 10px;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.history-event.new {
    background-color: #ffcbd559;
}

.history-event .title {
    font-weight: bold;
    text-transform: uppercase;
}

.history-event .message {

}

.history-event .actions {

}

.history-event .date {
    display: inline-block;
    font-size: 0.9em;
}

.zone-request {
    padding: 5px 10px;
    border-bottom: 1px solid #EEE;
    line-height: 1.1;
    position: relative;
}

.zone-request.new {
    background-color: #ffcbd559;
}

.zone-request .title {
    font-weight: bold;
    text-transform: uppercase;
}

.zone-request .message {
    max-width: 350px;
}

.zone-request .actions {
    padding: 0;
    margin: 0;
    position: relative;
    bottom: 16px;
    height: 16px;
}

.zone-request .date {
    display: inline-block;
    font-size: 0.9em;
}

.ant-list-pagination {
    text-align: center;
}