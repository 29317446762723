.auth-form {
    max-width: 300px;
    margin: 20% auto;
    background: '#F4F4F9';
    border:10px;
}
.auth-form-forgot {
    float: right;
}
.ant-col-rtl .auth-form-forgot {
    float: left;
}
.auth-form-button {
    width: 100%;
}

.layout-from-page {
    background: rgba(255, 255, 255, .2);

}

.img-background {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  
    /* Зависит от размера изображения */
    @media screen and (max-width: 640px){
      img.bg {
        left: 50%;
        margin-left: -320px;
      }
    }
}

.input-style {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    width: 100%;
}
